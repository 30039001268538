import {createAudioSrc} from "./playRaw";

function mergeBuffers(channelBuffer, recordingLength) {
  let result = new Float32Array(recordingLength);
  let offset = 0;

  for (let i = 0; i < channelBuffer.length; i++) {
    result.set(channelBuffer[i], offset);
    offset += channelBuffer[i].length;
  }

  return result;
}


export class Recorder {
  constructor(sampleRate=16000) {
    this.sampleRate = sampleRate;
    this.leftChannel = [];
    this.recordingLength = 0;
    this.onaudioprocess = null;
  }

  async connect() {
    this.audioStream = await navigator.mediaDevices.getUserMedia({audio: {channelCount: 1}});

    // creates an instance of audioContext
    this.audioContext = new AudioContext({sampleRate: this.sampleRate});

    // creates an audio node from the microphone incoming stream
    this.audioInput = this.audioContext.createMediaStreamSource(this.audioStream);

    /* From the spec: This value controls how frequently the audioprocess event is
    dispatched and how many sample-frames need to be processed each call.
    Lower values for buffer size will result in a lower (better) latency.
    Higher values will be necessary to avoid audio breakup and glitches */
    const bufferSize = 2048;
    this.recorder = (this.audioContext.createScriptProcessor ||
      this.audioContext.createJavaScriptNode).call(this.audioContext,
      bufferSize,
      1,
      1);


    this.recorder.onaudioprocess = (event) => {
      const samples = event.inputBuffer.getChannelData(0);

      // we clone the samples
      const data = new Float32Array(samples);
      this.leftChannel.push(data);
      this.recordingLength += bufferSize;
      this.onaudioprocess && this.onaudioprocess(pcm32fToPcm16i(data));
    };

    // we connect the recorder
    this.audioInput.connect(this.recorder);

    // start recording
    this.recorder.connect(this.audioContext.destination);

  }

  disconnect() {
    this.recorder.disconnect();
    // play recorded audio
    // const audioContext = new AudioContext();
    // const source = audioContext.createBufferSource();
    // const audioBuffer = audioContext.createBuffer(1, this.recordingLength, this.sampleRate);
    // audioBuffer.copyToChannel(mergeBuffers(this.leftChannel, this.recordingLength), 0);
    // source.buffer = audioBuffer;
    // source.connect(audioContext.destination);
    // source.start();
  }

  getData() {
    const PCM32fSamples = mergeBuffers(this.leftChannel, this.recordingLength);

    return pcm32fToPcm16i(PCM32fSamples);
  }

  async getSrc() {
    return await createAudioSrc(this.getData().buffer, this.sampleRate, 1);
  }
}

function pcm32fToPcm16i(pcm32fSamples) {
  const PCM16iSamples = new Int16Array(pcm32fSamples.length);

  for (let i = 0; i < pcm32fSamples.length; i++) {
    let val = Math.floor(32767 * pcm32fSamples[i]);
    val = Math.min(32767, val);
    val = Math.max(-32768, val);

    PCM16iSamples[i] = val;
  }

  return PCM16iSamples;
}
