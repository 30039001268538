import {useCallback, useEffect, useRef} from "react";
import {useRefFunc} from "./useRefFunc";

export function useWebsocket({url, onClose, onOpen, onMessage, onError}) {
  const websocketRef = useRef(null);

  const onCloseRef = useRefFunc(onClose);
  const onOpenRef = useRefFunc(onOpen);
  const onMessageRef = useRefFunc(onMessage);
  const onErrorRef = useRef(onError);

  const initWebsocket = useCallback(() => {
    const ws = new WebSocket(url);
    if (onOpenRef.current) {
      ws.onopen = () => {
        onOpenRef.current();
      };
    }

    if (onCloseRef.current) {
      ws.onclose = (e) => {
        console.log(e);
        onCloseRef.current();
      };
    }

    if (onMessageRef.current) {
      ws.onmessage = (e) => {
        onMessageRef.current(e);
      };
    }
    ws.onerror = (e) => {
      if (onErrorRef.current) {
        onErrorRef.current(e);
      }
    }

    return ws;
  }, [url]);

  useEffect(() => {
    websocketRef.current = initWebsocket();

    return () => {
      websocketRef.current.close();
    };
  }, [url]);

  const send = useCallback((data) => {
    if (websocketRef.current && websocketRef.current.readyState === WebSocket.CLOSED) {
      websocketRef.current = initWebsocket();
    }
    websocketRef.current.send(data);
  }, [initWebsocket]);

  return {websocketRef, send};
}
